.messagesContainer {
    --topOffset: 45px;
    --topAppBarHeight: 100px;

    height: calc(97vh - (var(--topOffset) + var(--topAppBarHeight)));
    margin-bottom: 1rem;
}

.contactInfoContent,
.contactListContent {
    overflow-y: auto;
    height: 100%;
}
.contactInfoContent::-webkit-scrollbar,
.contactListContent::-webkit-scrollbar {
    width: 0px;
}

.conversation {
    overflow: auto;
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
}
.conversation::-webkit-scrollbar {
    width: 0px;
}

.personalInfoPanel {
    background: transparent !important;
    margin: "2rem 0";
    border-bottom: 1px solid #ededed !important;
    padding-bottom: 1rem;
}

.personalInfoPanel :global(.MuiAccordionSummary-content) * {
    font-size: 15px;
    font-weight: 500;
    color: #636363;
}

:global(.MuiAccordionDetails-root) {
    padding-bottom: 2rem !important;
}

:global(.MuiPaper-root) {
    margin: 0 !important;
}

:global(.MuiPaper-root)::before {
    background-color: transparent !important;
}

:global(.MuiAccordion-root) {
    box-shadow: none !important;
    border-bottom: 1px solid #ededed;
    background-color: transparent !important;
}

.contact-info {
    background-color: aquamarine;
}

.contactInfoRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.contactInfoLabel {
    min-width: 100px;
    font-size: 13px;
}

.contactInfoValue {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
}
